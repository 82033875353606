import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Carito from './Compra/Carito';
import Onloop from '../Image/ONLOOPLOGO.svg';
import carritoImg from '../Image/CARRITO.svg';
import { Badge, Dialog, DialogContent, InputAdornment, TextField, Typography } from '@mui/material';
import { CartContext } from "../Context/CardContext"; 
import SearchIcon from "@mui/icons-material/Search";
// import usuarioImg from '../Image/usuario.svg';

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: #333;
  color: white;
`;

const LogoButton = styled(Link)`
  height: 95px;
  background-image: url(${Onloop});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: inherit;
`;

const CancelButton = styled(Button)`
  && {
    color: white;
    margin: 0 1rem;
    text-transform: none;
    font-size: 20px;
  }
`;

const Menu = styled.div`
  display: flex;
  @media (max-width: 768px) {
    display: none;
  }
`;

const MenuItem = styled(Button)`
  && {
    color: white;
    margin: 0 1rem;
    text-transform: none;
    font-size: 20px;
  }
`;

const Hamburger = styled.div`
  display: none;
  cursor: pointer;
  margin-right: auto; /* Alinea el icono de menú a la izquierda */

  @media (max-width: 768px) {
    display: block;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Navbar = ({ fixed, searchText, setSearchText }) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const { cantidadEnCarrito } = useContext(CartContext);

  const toggleDrawer = (newOpen) => () => {
    setIsOpen(newOpen);
  };

  const toggleMenuDrawer = (newOpen) => () => {
    setMenuOpen(newOpen);
  };

  const isConfirmationPage = location.pathname === '/confirmacion';

  const CarritoComponent = (
    <Box sx={{ width: 250 }} role="presentation">
      <Grid container style={{ textAlign: 'center', justifyContent: 'space-between', padding: '1.3rem' }}>
        <Typography
            variant="h3"
            sx={{
              fontSize: '1.5rem',
              fontFamily: 'Outfit, sans-serif',
              fontWeight: 'bold',
              marginBottom: '1rem',
            }}
          >
            Tu carro
          </Typography>        
        <Button onClick={toggleDrawer(false)} sx={{ color: 'black' }}><CloseIcon /></Button>
      </Grid>
      <Divider />
      <Carito toggleDrawer={toggleDrawer} />
    </Box>
  );

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleMenuDrawer(false)}>
      <List>
        {[
          { text: 'Inicio', href: '/' },
          { text: 'Productos', href: '/productos' },
          { text: 'Nosotros', href: '/nosotros' },
          { text: 'Contacto', href: '/contacto' },
          { text: 'Blog', href: '/blog' },
        ].map(({ text, href }) => (
          <ListItem key={href} disablePadding>
            <ListItemButton onClick={() => (window.location.href = href)}>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  );

  const inputRef = useRef(null);

  useEffect(() => {
    if (openDialog) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  }, [openDialog]);

  const handleSearch = (value) => {
    if (value.trim() !== "") {
      navigate(`/productos?search=${value}`);
    }
  };

  return (
    <Nav fixed={fixed}>
      {isConfirmationPage ? (
        <>
          <LogoButton 
            onClick={() => window.location.href = '/'} 
          />
            <CancelButton
              variant="outlined"
              onClick={() => window.location.href = '/'}
              sx={{
                fontFamily: 'Outfit, sans-serif',
                fontWeight: 400,                 
                textAlign: 'center',             
                textJustify: 'inter-word',       
              }}
            >
              Seguir comprando
            </CancelButton>
        </>
      ) : (
        <>
          <Hamburger onClick={toggleMenuDrawer(true)}>
            <MenuIcon style={{ color: 'white' }} />
          </Hamburger>
            <LogoButton 
              onClick={() => window.location.href = '/'} 
            />
          <Menu>
            <MenuItem 
              component={Link} 
              onClick={() => window.location.href = '/'}  
              sx={{ 
                fontFamily: 'Outfit, sans-serif', 
                fontWeight: 400, 
                color: 'black', 
                padding: '10px 20px', 
                '&:hover': {
                  backgroundColor: '#ff4d4d', 
                  color: 'white'
                },
                textDecoration: 'none'
              }}
            >
              Inicio
            </MenuItem>

            <MenuItem 
              component={Link} 
              to="/productos" 
              sx={{ 
                fontFamily: 'Outfit, sans-serif', 
                fontWeight: 400, 
                color: 'black', 
                padding: '10px 20px', 
                '&:hover': {
                  backgroundColor: '#ff4d4d', 
                  color: 'white'
                },
                textDecoration: 'none'
              }}
            >
              Productos
            </MenuItem>

            <MenuItem 
              component={Link} 
              to="/nosotros" 
              sx={{
                fontFamily: 'Outfit, sans-serif', 
                fontWeight: 400, 
                color: 'black', 
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: '#ff4d4d', 
                  color: 'white'
                },
                textDecoration: 'none'
              }}
            >
              Nosotros
            </MenuItem>

            <MenuItem 
              component={Link} 
              to="/contacto" 
              sx={{ 
                fontFamily: 'Outfit, sans-serif', 
                fontWeight: 400, 
                color: 'black', 
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: '#ff4d4d', 
                  color: 'white'
                },
                textDecoration: 'none'
              }}
            >
              Contacto
            </MenuItem>

            <MenuItem 
              component={Link} 
              to="/blog" 
              sx={{ 
                fontFamily: 'Outfit, sans-serif', 
                fontWeight: 400, 
                color: 'black', 
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: '#ff4d4d', 
                  color: 'white'
                },
                textDecoration: 'none'
              }}
            >
              Blog
            </MenuItem>
          </Menu>

          <IconContainer>
            {/* Iconos para usuario y carrito */}
            {/* <IconButton component={Link} to="/iniciar-sesion" style={{ color: 'white' }}>
              <img src={usuarioImg} alt="Iniciar Sesión" style={{ width: '30px', height: '30px' }} />
            </IconButton> */}

              <IconButton
                onClick={() => setOpenDialog(true)}
                sx={{
                  display: location.pathname === "/productos" ? "none" : "inline-flex",
                  backgroundColor: "transparent",
                    "& .MuiBadge-badge": {
                      backgroundColor: "#ff4d4d",
                      color: "#ffffff",
                  },
                }}
              >
                <SearchIcon sx={{ color: "#ffffff",
                   "&:hover": {
                    color: "#ffffff",
                  },}} />
              </IconButton>

              <IconButton
                onClick={toggleDrawer(true)}
                style={{ padding: 8 }}
                aria-label="Carrito"
              >
                <Badge
                  badgeContent={cantidadEnCarrito()}
                  color="secondary"
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    "& .MuiBadge-badge": {
                      backgroundColor: "#ff4d4d",
                      color: "#ffffff",
                    },
                  }}
                >
                  <img
                    src={carritoImg}
                    alt="Carrito"
                    style={{ width: "30px", height: "30px"}}
                    />
                </Badge>
            </IconButton>
          </IconContainer>

          {/* Dialog con el Buscador */}
          <Dialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            sx={{
              "& .MuiDialog-paper": {
                position: "absolute",
                top: "15%",
                transform: "translateY(-20%)",
                width: "90%",
                maxWidth: "600px",
              },
            }}
          >
            <DialogContent>
              <Box
                component="form"
                onSubmit={(e) => {
                  e.preventDefault();
                  const searchValue = e.target[0].value;
                  handleSearch(searchValue);
                  setOpenDialog(false);
                }}
              >
                {/* Dependiendo de la ruta, mostramos un texto diferente */}
                  <TextField
                    id="buscar-productos"
                    placeholder="Buscar productos..."
                    variant="standard"
                    required
                    fullWidth
                    inputRef={inputRef}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        fontFamily: 'Poppins, sans-serif',
                        fontWeight: 400,
                        fontSize: '16px',
                        padding: '10px',
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={(e) => {
                              e.preventDefault();
                              const form = e.target.closest("form");
                              const searchValue = form ? form[0].value : "";
                              handleSearch(searchValue);
                              setOpenDialog(false);
                            }}
                            sx={{
                              backgroundColor: '#b63232',
                              borderRadius: '50%',
                              padding: '10px',
                              color: '#ffffff',
                              '&:hover': {  
                                backgroundColor: '#b63232',
                              },
                            }}
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
              </Box>
            </DialogContent>
          </Dialog>

          <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)}>
            {CarritoComponent}
          </Drawer>
          <Drawer anchor="left" open={menuOpen} onClose={toggleMenuDrawer(false)}>
            {DrawerList}
          </Drawer>
        </>
      )}
    </Nav>
  );
};

export default Navbar;