import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ItemList from './ItemList';
import SearchBar from './SearchBar';
import { Box, Typography, Pagination, CircularProgress } from '@mui/material';

const ItemListContainer = ({ searchText, setSearchText }) => {
  const [productos, setProductos] = useState([]);
  const [sortOption, setSortOption] = useState('default');
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 12;

  // Obtiene la categoría desde la URL al montar el componente
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const searchParam = urlParams.get('search');
    const categoryParam = urlParams.get('category');

    if (searchParam) setSearchText(searchParam);
    if (categoryParam) setSelectedCategory(categoryParam);
  }, [setSearchText]);

  // Obtiene la lista de categorías
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://onloop.cl/api/categorias.php');
        if (response.data.success) {
          setCategories(response.data.categories.map((cat) => cat.trim()));
        }
      } catch (err) {
        console.error('Error al obtener las categorías:', err.message);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    if (selectedCategory === '' && new URLSearchParams(window.location.search).get('category')) {
      return; // Espera a que la categoría se setee antes de hacer la peticion
    }

    const controller = new AbortController();
    const signal = controller.signal;

    const fetchProductos = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.post(
          'https://onloop.cl/api/productos.php',
          {
            query: searchText,
            category: selectedCategory || null,
            sort: sortOption,
            offset: (page - 1) * itemsPerPage,
            limit: itemsPerPage,
            orderBy: sortOption !== 'default' ? (sortOption.includes('ASC') ? sortOption.slice(0, -3) : sortOption.slice(0, -4)) : null,
            order: sortOption !== 'default' ? (sortOption.includes('ASC') ? 'ASC' : 'DESC') : null,
          },
          { signal }
        );

        if (response.data.success) {
          setProductos(response.data.data);
          setTotal(response.data.count);
        } else {
          setError(response.data.message);
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          console.log('Solicitud cancelada:', err.message);
        } else {
          setError('Error al obtener los productos: ' + err.message);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchProductos();

    return () => controller.abort();
  }, [page, searchText, sortOption, selectedCategory, selectedBrands]);

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setPage(1);

    // Actualizar la URL cuando el usuario cambia la categoría
    const url = new URL(window.location);
    url.searchParams.set('category', e.target.value);
    window.history.pushState({}, '', url);
  };

  return (
    <Box>
      <SearchBar
        searchText={searchText}
        setSearchText={setSearchText}
        sortOption={sortOption}
        setSortOption={setSortOption}
        selectedBrands={selectedBrands}
        categories={categories}
        selectedCategory={selectedCategory}
        handleCategoryChange={handleCategoryChange}
      />
      <Typography variant="h4" sx={{ fontWeight: 'bold', marginTop: '1rem', textAlign: 'center' }}>
        Productos
      </Typography>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {error && <Typography color="error" sx={{ textAlign: 'center' }}>{error}</Typography>}
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '1rem', justifyContent: 'center' }}>
            <ItemList productos={productos} page={page} />
          </Box>
          {total > itemsPerPage && (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
              <Pagination
                count={Math.ceil(total / itemsPerPage)}
                page={page}
                onChange={(event, value) => setPage(value)}
                variant="outlined"
                color="primary"
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ItemListContainer;
