import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

function AprobadoTrans() {
  const navigate = useNavigate();
  const location = useLocation();
  const [success, setSuccess] = useState(false);
  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current) return;
    effectRan.current = true;

    const paymentData = sessionStorage.getItem('paymentData');
    console.log('Datos en sessionStorage:', paymentData);

    if (paymentData) {
      try {
        const parsedData = JSON.parse(paymentData);
        console.log('Datos parseados:', parsedData);

        const enviarDatosAlBackend = async (url, paymentData = null) => {
          const productos = paymentData.productos.map((producto) => {
            let costeAdicionalModificado = producto.coste_adicional;

            if (producto.cantidad === 1 && producto.coste_adicional > 0) {
              costeAdicionalModificado = 0;
            } else if (producto.cantidad >= 2 && producto.coste_adicional > 0) {
              costeAdicionalModificado = (producto.cantidad - 1) * 2000;
            }

            return {
              id_producto: producto.id,
              titulo: producto.titulo,
              precio: producto.precio,
              cantidad: producto.cantidad,
              coste_adicional: costeAdicionalModificado,
            };
          });

          // const totalCosteAdicional = productos.reduce(
          //   (total, producto) => total + producto.coste_adicional,
          //   0
          // );

          // const totalAPagar =
          //   parseInt(paymentData.precio_total, 10) +
          //   parseInt(paymentData.precio_comuna, 10) +
          //   totalCosteAdicional;

          try {
            const response = await fetch(url, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: new URLSearchParams({
                compra_id: paymentData.compra_id,
                nombre: paymentData.nombre,
                rut: paymentData.rut,
                telefono: paymentData.telefono,
                correo: paymentData.correo,
                precio_total: paymentData.precio_total || 0,
                precio_comuna: paymentData.precio_comuna || 0,
                total_pagar: paymentData.total_pagar || 0,
                medio_pago: paymentData.medioPago || '',
                productos: JSON.stringify(productos),
                update_stock: true,
                envio: paymentData.envio || '',
                ...(paymentData.envio !== 'Retiro' && {
                  direccion: paymentData.direccion || '',
                  comuna: paymentData.comuna || '',
                  region: paymentData.region || '',
                }),
                ...(paymentData.facturacion && {
                  facturacion: paymentData.facturacion,
                  rut_empresa: paymentData.rutEmpresa || '',
                  razon_social: paymentData.razonSocial || '',
                  giro: paymentData.giro || '',
                  direccion_empresa: paymentData.direccionEmpresa || '',
                }),
              }),
            });

            if (!response.ok) {
              const errorText = await response.text();
              console.error(
                `Error en la solicitud a ${url}:`,
                response.statusText,
                errorText
              );
              throw new Error(`Error en la solicitud: ${response.statusText}`);
            }

            const result = await response.json();
            console.log('Resultado del servidor:', result);

            if (result.success) {
              setSuccess(true);
              return result;
            } else {
              throw new Error(result.error || 'Error desconocido');
            }
          } catch (error) {
            console.error(`Error en la solicitud a ${url}:`, error.message);
            throw error;
          }
        };

        enviarDatosAlBackend(
          'https://onloop.cl/api/actualizar_compra.php',
          parsedData
        ).then(async () => {
          try {
            const comprobanteUrl =
              parsedData.region === 'Metropolitana de Santiago' || parsedData.envio === 'Retiro'
                ? 'https://onloop.cl/api/comprobante_completo.php'
                : 'https://onloop.cl/api/comprobante_incompleto.php';

            console.log(comprobanteUrl === 'https://onloop.cl/api/comprobante_completo.php' ? 'comprobante_completo' : 'comprobante_incompleto');

            const comprobanteResponse = await enviarDatosAlBackend(comprobanteUrl, parsedData);
            console.log('Comprobante enviado exitosamente:', comprobanteResponse);
          } catch (error) {
            console.error('Error al enviar los datos al backend de comprobante:', error.message);
          }
        });

        sessionStorage.removeItem('paymentData');

        setTimeout(() => {
          navigate('/');
        }, 5000);
      } catch (error) {
        console.error('Error al parsear paymentData:', error);
        navigate('/');
      }
    } else {
      console.error('No se han encontrado datos');
      navigate('/');
    }
  }, [location, navigate]);

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f4f4f4',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: '30px',
          borderRadius: '10px',
          textAlign: 'center',
          maxWidth: '400px',
          backgroundColor: '#fff',
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Compra completada
        </Typography>
        <Typography variant="body1" component="p" sx={{ mt: 2 }}>
          Comprobaremos si su transferencia fue exitosa.
        </Typography>
        <Typography variant="body1" component="p" sx={{ mt: 1 }}>
          En breve recibirá un comprobante en su correo.
        </Typography>
        {success && (
          <Typography
            variant="body1"
            component="p"
            sx={{ mt: 2, color: 'green' }}
          >
            Regresaras a la página de inicio en unos momentos...
          </Typography>
        )}
      </Paper>
    </Box>
  );
}

export default AprobadoTrans;
